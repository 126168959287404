.auth {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 40px 12px;
}

.auth__container {
  width: 100%;
  max-width: 360px;
  height: 100%;
  max-height: 624px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth__logo-block {
  flex: auto;
}

.auth__logo-box {
  display: flex;
  align-items: center;
}

.auth__logo-icon {
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth__logo-text {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 120%;
  color: #2d6956;
  margin: 0;
  padding-left: 8px;
}

.auth__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-top: 24px;
}

.auth__title {
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 120%;
  margin: 0;
}

.auth__inputs-block {
  width: 100%;
  display: grid;
  gap: 24px;
  margin-top: 60px;
}

.auth__input-box {
  width: 100%;
  display: grid;
  gap: 8px;
  position: relative;
}

.auth__label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.auth__input {
  width: 100%;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
  background: linear-gradient(0deg, #f3f7f3, #f3f7f3), #ffffff;
  border-radius: 12px;
  border: 1px solid transparent;
  box-sizing: border-box;
  padding: 18px 16px;
  transition: border-color 0.3s ease;
}

.auth__input_type_password {
  padding: 18px 44px 18px 16px;
}

.auth__input:focus {
  border-color: #7b61ff;
  outline: transparent;
}

.auth__input_error {
  border-color: #ba1b1b;
}

.auth__error-message {
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  font-size: 10px;
  color: #ba1b1b;
}

.auth__input-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50px;
  right: 16px;
  background: none;
  border: none;
  padding: 0;
  z-index: 1;
  cursor: pointer;
}

.auth__submit-button {
  min-height: 48px;
  width: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  background: #2d6956;

  border: none;
  border-radius: 60px;
  box-sizing: border-box;
  padding: 12px 65px;
  margin-top: 36px;
  cursor: pointer;
  transition: background-color 0.3s ease-in, opacity 0.3s ease-in-out;
}

.auth__submit-button:hover {
  background: #00a297;
}

.auth__submit-button_disabled {
  background: #c5c7c6;
  pointer-events: none;
  opacity: 0.3;
}


.auth__submit-error {
  color: #AE1500;

  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  margin: 24px 0 0;
  min-height: 16px;
}