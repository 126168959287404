.notes {}

.notes__add-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    border-radius: 16px;
    background: #CDE9DE;
    box-sizing: border-box;

    border: none;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;

}

.notes__add-btn-icon {
    width: 24px;
    height: 24px;
    margin: 0 12px 0 0;
}

.notes__add-btn-text {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin: 0;
}

.notes__new-note {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: #E3ECE6;
    border-radius: 16px;
    width: 100%;
    max-width: 960px;
    box-sizing: border-box;
}

.notes__note-date {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */


    color: #525252;
    margin: 0;
}


.notes__note-name-input {

    -webkit-appearance: none;
    background-color: unset;
    font: unset;
    text-rendering: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    appearance: unset;
    font-style: normal;
    border: none;
    transition: color 0.2s linear;
    transition: background-color 0.2s linear, border-color 0.2s ease-in-out;


    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */
    color: #000000;




    width: 100%;
    margin: 8px 0 0;
}



.notes__note-name-input:focus {
    outline: none;
}

.notes__note-name-input::placeholder {
    color: #ADADAD;
}




.notes__note-textarea {

    -webkit-appearance: none;
    background-color: unset;
    font: unset;
    text-rendering: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    appearance: unset;
    font-style: normal;
    border: none;
    transition: color 0.2s linear;
    transition: background-color 0.2s linear, border-color 0.2s ease-in-out;

    white-space: pre-line;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 29px */
    color: #000000;
    resize: none;
    min-height: 118px;


    width: 100%;
    margin: 8px 0 0;
}



.notes__note-textarea:focus {
    outline: none;
}

.notes__note-textarea::placeholder {
    color: #ADADAD;
}


.notes__note-btns {
    display: flex;
    flex-direction: row;
    margin: 14px 0 0 auto;
}

.notes__note-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    width: fit-content;
    border-radius: 100px;

    transition: opacity 0.3s ease-in-out;
    border: none;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
}

.notes__note-btn_inactive{
    opacity: 0.3;
}

.notes__note-btn_type_cancel {
    margin: 0 14px 0 0;
    background-color: transparent;
}

.notes__note-btn_type_save {
    background: #006B58;
    min-width: 77px;
}

.notes__note-btn-text {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
}

.notes__note-btn-text_type_cancel {
    color: #006B58;
}

.notes__note-btn-text_type_save {
    color: #FFFFFF;
}


.notes__cards{
    display: flex;
    flex-direction: column;
    margin: 24px 0 0;
}

.notes__card {
    margin: 24px 0 0;
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: #E3ECE6;
    border-radius: 16px;
    width: 100%;
    max-width: 960px;
    box-sizing: border-box;
}
.notes__card:first-of-type{
    margin: 0;
}

.notes__card-date-and-btns{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 24px;
}

.notes__card-redact-btns{
    display: flex;
    flex-direction: row;

    
}

.notes__card-redact-btn{
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: none;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    margin: 0 16px 0 0;
    display: flex;
    align-items: center;
    justify-content: center
}

.notes__card-redact-btn:last-of-type{
    margin: 0;
}



.notes__card-date {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */


    color: #525252;
    margin: 0;
}


.notes__card-name-input {

    -webkit-appearance: none;
    background-color: unset;
    font: unset;
    text-rendering: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    appearance: unset;
    font-style: normal;
    border: none;
    transition: color 0.2s linear;
    transition: background-color 0.2s linear, border-color 0.2s ease-in-out;


    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */
    color: #000000;




    width: 100%;
    margin: 8px 0 0;
}



.notes__card-name-input:focus {
    outline: none;
}

.notes__card-name-input::placeholder {
    color: #ADADAD;
}




.notes__card-textarea {

    -webkit-appearance: none;
    background-color: unset;
    font: unset;
    text-rendering: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    appearance: unset;
    font-style: normal;
    border: none;
    transition: color 0.2s linear;
    transition: background-color 0.2s linear, border-color 0.2s ease-in-out;

    white-space: pre-line;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 29px */
    color: #000000;
    resize: none;
    min-height: 118px;


    width: 100%;
    margin: 8px 0 0;
}



.notes__card-textarea:focus {
    outline: none;
}

.notes__card-textarea::placeholder {
    color: #ADADAD;
}


.notes__card-btns {
    display: flex;
    flex-direction: row;
    margin: 14px 0 0 auto;
}

.notes__card-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    width: fit-content;
    border-radius: 100px;

    transition: opacity 0.3s ease-in-out;
    border: none;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
}

.notes__card-btn_inactive{
    opacity: 0.3;
}

.notes__card-btn_type_cancel {
    margin: 0 14px 0 0;
    background-color: transparent;
}

.notes__card-btn_type_save {
    background: #006B58;
    min-width: 77px;
}



.notes__card-btn-text {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
}

.notes__card-btn-text_type_cancel {
    color: #006B58;
}

.notes__card-btn-text_type_save {
    color: #FFFFFF;
   
}


.notes__card-edited{
    margin: 14px 0 0 auto;

    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */


    color: #525252;
}