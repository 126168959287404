.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__content-box {
  width: 100%;
  max-width: 1440px;
  display: grid;
  grid-template-columns: repeat(3, minmax(min-content, max-content));
  grid-template-areas: "back logo logout";
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 40px 70px 0;
  margin: 0 auto;
}

.header__back-button {
  grid-area: back;
  display: flex;
  align-items: center;
  text-decoration: none;
  align-self: flex-end;
  cursor: pointer;
  transition: opacity 0.3s ease-in;
}

.header__back-button:hover {
  opacity: 0.8;
}

.header__back-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__back-text {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  color: #000000;
  margin: 0;
  padding-left: 12px;
}

.header__logo {
  grid-area: logo;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  top: 36px;
  position: absolute;
}

.header__logo-icon {
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__logo-text {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 120%;
  color: #2d6956;
  margin: 0;
  padding-left: 8px;
}

.header__logout-button {
  grid-area: logout;
  display: flex;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #2d6956;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: color 0.3s ease-in;

  /* width: 143px;
  text-align: right; */

}

.header__logout-button:hover {
  color: #00a297;
}

@media (max-width: 1280px) {
  .header__content-box {
    padding: 40px 32px 0;
  }
}

@media (max-width: 800px) {
  .header__content-box {
    padding: 40px 12px 0;
  }
}
