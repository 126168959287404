.main {
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 80px 70px;
  margin: 0 auto;
}

.main__title {
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 120%;
  margin: 0;
}

.main__table {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f3f7f3;
  border-radius: 32px;
  border: none;
  overflow: auto !important;
  box-sizing: border-box;
  padding: 32px 0;
  margin-top: 32px;
}

.main__table-tr-head {
  width: calc(100% - 24px - 16px);
  display: grid;
  grid-template-columns: 44px 152px 150px 205px 252px 116px 135px;
  justify-content: space-between;
  gap: 20px;
  margin: 0 24px 18px 16px;
}

.main__table-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  text-align: left;
  color: #717975;
  padding: 0;

  white-space: nowrap;
}

.main__table-title_type_id {
  text-align: right;
}

.main__table-tr-body {
  width: calc(100% - 24px - 16px);
  display: grid;
  grid-template-columns: 44px 152px 150px 205px 252px 116px 135px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #dde5df;
  box-sizing: border-box;
  padding: 18px 0;
  margin: 0 24px 0 16px;
}

.main__table-tr-body:last-of-type {
  border: none;
}

.main__table-th-body {
  text-align: left;
  padding: 0;
}

.main__table-th-body_type_id {
  text-align: right;
}

.main__table-id-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  margin: 0;
}

.main__table-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  margin: 0;
}

.main__medicine-box {
  width: fit-content;
  display: flex;
  align-items: center;
  background: rgba(45, 105, 86, 0.08);
  border-radius: 100px;
  box-sizing: border-box;
  padding: 8px 12px;
  max-width: 100%;
  overflow: hidden;
}

.main__medicine-image {
  width: 24px;
  height: 24px;
  object-fit: contain;
  object-position: center;
}

.main__medicine-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  margin: 0;
  padding-left: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main__result-box {
  display: flex;
  align-items: center;
}

.main__result-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}

.main__intake-box {
  width: fit-content;
  display: flex;
  border-radius: 100px;
  box-sizing: border-box;
  padding: 8px 12px;
}

.main__intake-box_type_no-skips {
  background: #ccffe7;
  color: #2d6956;
}

.main__intake-box_type_skip-once {
  background: #ffe49e;
  color: #5e4300;
}

.main__intake-box_type_skip-twice {
  background: #ffdad0;
  color: #410001;
}

.main__intake-box_type_skip-often{
  color: #ffffff;
  background: #00150F;
}

.main__patient-link {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
}

.main__reminder-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  color: #2d6956;
  background: transparent;
  border: 2px solid #2d6956;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 8px 0;
  cursor: pointer;
}

@media (max-width: 1280px) {
  .main {
    padding: 80px 32px;
  }
}

@media (max-width: 800px) {
  .main {
    padding: 80px 12px;
  }
}

.main__table-preloader{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-top: 18px;
  padding-bottom: 18px;
  overflow: hidden;
}