.app{

}

.app__preloader{
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
}