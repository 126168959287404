.medical-data {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.medical-data__patient-data {
    display: flex;
    flex-direction: column;
    background: #E3ECE6;
    border-radius: 24px;
    padding: 16px;
    height: fit-content;
    margin: 0 24px 0 0;
}

.medical-data__patient-data_syndrome {
    width: 335px;
    overflow: hidden;
}

.medical-data__patient-data:last-of-type {
    margin: 0;
}

.medical-data__patient-data-title {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */


    color: #4C5350;
    margin: 0;
}

.medical-data__patient-data-item {
    display: flex;
    flex-direction: column;
    margin: 20px 0 0;
}

.medical-data__patient-data-item:first-of-type {
    margin: 28px 0 0;
}

.medical-data__patient-data-item-title {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */


    color: #808884;

    margin: 0;
}

.medical-data__patient-data-item-value {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;


    color: #434343;
    margin: 4px 0 0;
}

.medical-data__patient-data-syndrome {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */


    color: #000000;
    margin: 37px 0 0;
}

.medical-data__syndrome-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #E3ECE6;
    border-radius: 16px;
    box-sizing: border-box;
    width: 100%;
    margin: 24px 0 0;
}

.medical-data__syndrome-item-name {

    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 120%;

    color: #000000;

    margin: 0;
}

.medical-data__syndrome-item-card {
    margin: 16px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.medical-data__syndrome-item-card-name {
    margin: 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */


    color: #808884;

}

.medical-data__syndrome-item-card-value-container {


    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';

    caret-color: white;
    -webkit-appearance: none;
    width: 100%;
    background-color: unset;
    font: unset;
    text-rendering: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    appearance: none !;

    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 29px */

    display: flex;
    align-items: center;

    color: #434343;
    padding: 0;

    border: none;
    position: relative;
    z-index: 1;

    cursor: pointer;
}

.medical-data__syndrome-item-values {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 4px 0 0;
    align-items: center;
    position: relative;
}

.medical-data__arrow {
    position: absolute;
    z-index: 0;
    right: 0;

}

.medical-data__syndrome-item-card-value-container:focus {
    outline: none;
}

.medical-data__syndrome-item-card-value-container::-ms-expand {
    display: none;
}

.medical-data__syndrome-item-card-value {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    margin: 0;
    max-width: calc(100% - 24px - 12px);
    color: #434343;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}