.intakes {
  width: 100%;
  max-width: 1116px;
  display: grid;
  grid-template-columns: 1fr 232px;
  justify-content: space-between;
  gap: 40px;
}

.intakes__table {
  width: 100%;
  max-width: 794px;
  display: flex;
  flex-direction: column;
  border: none;
  overflow: auto !important;
  box-sizing: border-box;
}

.intakes__table-tr-head {
  width: 100%;
  display: grid;
  grid-template-columns: 144px 150px 140px 140px 102px;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 18px;
}

.intakes__table-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  text-align: left;
  color: #717975;
  padding: 0;
}

.intakes__table-title_type_date {
  text-align: right;
}

.intakes__table-tr-body {
  width: 100%;
  display: grid;
  grid-template-columns: 144px 150px 140px 140px 102px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #dde5df;
  box-sizing: border-box;
  padding: 18px 0;
}

.intakes__table-tr-body:last-of-type {
  border: none;
}

.intakes__table-th-body {
  text-align: left;
  padding: 0;
}

.intakes__table-th-body_type_date {
  text-align: right;
}

.intakes__table-date-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  margin: 0;

  white-space: nowrap;
}

.intakes__medicine-box {
  width: fit-content;
  display: flex;
  align-items: center;
  background: rgba(45, 105, 86, 0.08);
  border-radius: 100px;
  box-sizing: border-box;
  padding: 8px 12px;

  max-width: 100%;
  overflow: hidden;
  text-decoration: none;
}

.intakes__medicine-image {
  width: 24px;
  height: 24px;
  object-fit: contain;
  object-position: center;
}

.intakes__medicine-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin: 0;
  padding-left: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000;
}

.intakes__table-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  margin: 0;

  white-space: nowrap;
}

.intakes__status-box {
  width: fit-content;
  display: flex;
  border-radius: 100px;
  box-sizing: border-box;
  padding: 8px 12px;

  max-width: 100%;
  overflow: hidden;
}

.intakes__status-box_type_skipped {
  color: #410001;
  background: #ffdad0;
}

.intakes__status-box_type_taken {
  color: #2d6956;
  background: #ccffe7;
}

.intakes__status-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  margin: 0 auto;

  overflow: hidden;
  text-overflow: ellipsis;
}

.intakes__results-block {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.intakes__total-numbers-box {
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  gap: 12px;
}

.intakes__total-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e3ece6;
  border-radius: 14px;
  box-sizing: border-box;
  padding: 12px;
}

.intakes__total-number {
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 140%;
  color: #121212;
  margin: 0;
}

.intakes__total-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #121212;
  margin: 0;
  padding-top: 4px;
}

.intakes__total-medicine-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.intakes__total-medicine-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  color: #121212;
  margin: 0;
}

.intakes__medicine-used-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(114px, 1fr));
  gap: 4px;
  margin-top: 4px;
}


.intakes__table-preloader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-top: 18px;
  padding-bottom: 18px;
  overflow: hidden;
}

.intakes__table-container {
  display: flex;
  flex-direction: column;
}

.intakes__selected-medicine {
  width: fit-content;
  display: flex;
  align-items: center;
  background: rgba(45, 105, 86, 0.08);
  border-radius: 100px;
  box-sizing: border-box;
  padding: 8px 12px;

  max-width: 100%;
  overflow: hidden;
  margin-bottom: 12px;
  max-width: 200px;
}

.intakes__selected-medicine-cross{
  width: 12px;
  height: 12px;
  margin: 0 0 0 12px;
  cursor: pointer;
}

.intakes__selected-medicine-text{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin: 0;
  color: #000000;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 12px - 12px - 24px);
}