.patient {
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 64px 70px 30px 70px;
  margin: 0 auto;
}

.patient__title-box {
  display: flex;
  align-items: center;
}

.patient__title-text {
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 120%;
  margin: 0;
}

.patient__id-text {
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 120%;
  background: #e7eeea;
  border-radius: 40px;
  box-sizing: border-box;
  margin: 0 0 0 14px;
  padding: 8px 16px;
}

.patient__profile {
  width: 100%;
  max-width: 1260px;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.patient__profile-nav {
  height: 44px;
  position: relative;
}

.patient__nav-button {
  display: flex;
  position: absolute;
  bottom: -16px;
  background: transparent;
  border: none;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
}

.patient__nav-button_type_medicalData {
  left: 0;
  z-index: 5;
}

.patient__nav-button_type_intakes {
  left: 174px;
  z-index: 4;
}

.patient__nav-button_type_history {
  left: 390px;
  z-index: 3;
}

.patient__nav-button_type_notes {
  left: 606px;
  z-index: 2;
}

.patient__nav-button_type_analysis {
  left: 722px;
  z-index: 1;
}

.patient__nav-button_type_analysis_disabled {
  opacity: 0.3;
  pointer-events: none;
}

.patient__nav-button_active {
  z-index: 10;
}

.left-nav-card-icon-fill {
  fill: #e7eeea;
  transition: fill 0.2s ease-in;
}

.patient__nav-button_active .left-nav-card-icon-fill {
  fill: #f3f7f3;
}

.right-nav-card-icon {
  position: relative;
  top: -4px;
}

.right-nav-card-icon-fill {
  fill: #e7eeea;
  box-shadow: 8px 4px 8px rgba(0, 0, 0, 0.04);
  transition: fill 0.2s ease-in;
}

.patient__nav-button_active .right-nav-card-icon-fill {
  fill: #f3f7f3;
}

.patient__btn-patient {
  background: #e7eeea;
  box-sizing: border-box;
  padding: 12px;
  transition: background-color 0.2s ease-in;
}

.patient__nav-button_type_medicalData .patient__btn-patient {
  border-radius: 28px 0px 0px 0px;
  padding: 12px 22px 12px 43px;
}

.patient__nav-button_active .patient__btn-patient {
  background: #f3f7f3;
}

.patient__nav-button-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: 0.3px;
  color: rgba(18, 18, 18, 0.82);
  margin: 0;
  text-decoration: none;
}

.patient__profile-content {
  position: relative;
  background: #f3f7f3;
  border-radius: 0px 32px 32px 32px;
  box-sizing: border-box;
  padding: 32px 24px;
  z-index: 10;
  min-height: 700px;
}

@media (max-width: 1280px) {
  .patient {
    padding: 64px 32px 30px 32px;
  }
}

@media (max-width: 800px) {
  .patient {
    padding: 64px 12px 30px 12px;
  }
}

.patient__profile-preloader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 500px;
}

.patient__id-preloader {
  background: #e7eeea;
  border-radius: 40px;
  box-sizing: border-box;
  margin: 0 0 0 14px;
  padding: 8px 16px;
  height: 69px;
  width: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}