.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 12px;
  visibility: hidden;
  opacity: 0;
  z-index: 1000;
  transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.popup__overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0;
  z-index: 9;
  transition: opacity 0.4s ease-in-out;
}

.popup_opened .popup__overlay {
  opacity: 0.6;
  transition: opacity 0.4s ease-in-out;
}

.popup__container {
  width: 100%;
  max-width: 402px;
  display: flex;
  flex-direction: column;
  background: #f3f7f3;
  border-radius: 24px;
  box-sizing: border-box;
  padding: 16px;
  z-index: 10;
  visibility: hidden;
  transition: visibility 0.3s ease-in-out;
}

.popup_opened .popup__container {
  visibility: visible;
}

.popup__title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  margin: 0;
}

.popup__id-box {
  display: flex;
  align-items: center;
}

.popup__id-text {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 120%;
  margin: 0;
}

.popup__id {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 120%;
  background: #e7eeea;
  border-radius: 40px;
  box-sizing: border-box;
  margin: 0 0 0 14px;
  padding: 8px 16px;
}

.popup__text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  margin: 0;
  padding-top: 16px;
}

.popup__buttons-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 175px);
  grid-auto-rows: 48px;
  gap: 20px;
  margin-top: 36px;
}

.popup__button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  border-radius: 60px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in;
}

.popup__button_type_cancel-logout {
  color: #ffffff;
  background: #2d6956;
}

.popup__button_type_cancel-logout:hover {
  background: #00a297;
}

.popup__button_type_cancel-reminder {
  color: #000000;
  background: transparent;
}

.popup__button_type_cancel-reminder:hover {
  background: #e8f3f0;
}

.popup__button_type_sure-logout {
  color: #2d6956;
  background: transparent;
}

.popup__button_type_sure-logout:hover {
  background: #e8f3f0;
}

.popup__button_type_sure-reminder {
  color: #ffffff;
  background: #2d6956;
}

.popup__button_type_sure-reminder:hover {
  background: #00a297;
}

.popup__button_type_cancel-delite {
  color: #ffffff;
  background: #2d6956;
}

.popup__button_type_sure-delite {
  background: transparent;
}

.popup__text_centered{
  text-align: center;
}

.popup__warning-icon{
  margin: 0 auto 8px;
}