.medicine-popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 12px;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
  transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.medicine-popup_opened {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.medicine-popup__overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #07120f;
  opacity: 0;
  z-index: 9;
  transition: opacity 0.4s ease-in-out;
}

.medicine-popup_opened .medicine-popup__overlay {
  opacity: 0.8;
  transition: opacity 0.4s ease-in-out;
}

.medicine-popup__container {
  width: 100%;
  max-width: 656px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 36px;
  box-sizing: border-box;
  padding: 24px;
  z-index: 10;
  visibility: hidden;
  transition: visibility 0.3s ease-in-out;
}

.medicine-popup_opened .medicine-popup__container {
  visibility: visible;
}

.medicine-popup__heading-box {
  display: flex;
  justify-content: space-between;
}

.medicine-popup__title-box {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}

.medicine-popup__icon-box {
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f7f3;
  border-radius: 6px;
}

.medicine-popup__icon {
  height: 33px;
  object-fit: contain;
  object-position: center;
}

.medicine-popup__name {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 120%;
  color: #191c1b;
  margin: 0;
  padding-left: 8px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.medicine-popup__close-button {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.medicine-popup__content-box {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 360px;
  gap: 24px;
  margin-top: 28px;
}

.medicine-popup__main-info {
  height: fit-content;
  display: grid;
  gap: 28px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.medicine-popup__info-item {
  display: flex;
  flex-direction: column;
}

.medicine-popup__info-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: #6f7974;
  margin: 0;
}

.medicine-popup__info-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #191c1b;
  margin: 0;
}

.medicine-popup__info-link {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.medicine-popup__link-text {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  text-decoration-line: underline;
  color: #414945;
  margin: 0;
}

.medicine-popup__link-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.medicine-popup__intakes-info {
  display: grid;
  gap: 16px;
}

.medicine-popup__days-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 38px;
  gap: 8px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.medicine-popup__day {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f8f6;
  border-radius: 5px;
  border: 2px solid transparent;
}

.medicine-popup__day_selected {
  border-color: #04b994;
}

.medicine-popup__day-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  margin: 0;
}

.medicine-popup__day_selected .medicine-popup__day-text {
  color: #04b994;
}

.medicine-popup__intakes-box {
  display: flex;
  flex-direction: column;
}

.medicine-popup__intakes-title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  color: #1c1b1f;
  margin: 0;
}

.medicine-popup__intakes-list {
  max-height: 388px;
  display: grid;
  gap: 8px;
  overflow-y: scroll;
  background: #fafdfa;
  border-radius: 16px;
  box-sizing: border-box;
  list-style: none;
  padding: 8px 4px 0 8px;
  margin: 12px 0 0;
}

.medicine-popup__intakes-list::-webkit-scrollbar {
  width: 4px;
}

.medicine-popup__intakes-list::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e9ede9;
  border-radius: 25px;
  outline: none;
}

.medicine-popup__intake-item {
  display: flex;
  flex-direction: column;
  background: linear-gradient(0deg,
      rgba(0, 111, 57, 0.04),
      rgba(0, 111, 57, 0.04)),
    #fafdfa;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 12px 16px;
}

.medicine-popup__intake-time {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 125%;
  color: #3f4945;
  margin: 0;
}

.medicine-popup__intake-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: #3f4945;
  margin: 0;
}

.medicine-popup__intake-text_span {
  color: #006B58;
}

.medicine-popup__intake-item-preloader {
  display: flex;
  flex-direction: column;

  border-radius: 16px;
  box-sizing: border-box;
  padding: 12px 16px;
  align-items: center;
  justify-content: center;
}