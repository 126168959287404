.history {
  width: 100%;
  max-width: 1121px;
  display: grid;
  gap: 25px;
}

.history__medicine-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.history__heading{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.history__title {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  margin: 0;
}

.history__cards-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  gap: 16px;
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
}

.history__medicine-card {
  width: 100%;
  display: grid;
  grid-template-columns: 52px 1fr 25px;
  gap: 12px;
  background: #fbfdfa;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 12px;
}

.history__medicine-card_discontinued {
  background: #ffdad0;
}

.history__medicine-icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f7f3;
  border-radius: 6px;
}

.history__medicine-card_discontinued .history__medicine-icon-box {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
    #ffdad0;
}

.history__medicine-icon {
  height: 33px;
  object-fit: contain;
  object-position: center;
}

.history__medicine-info-box {
  display: grid;
  gap: 12px;
  max-width: 100%;
  overflow: hidden;
}

.history__medicine-name {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: #191c1b;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.history__info-box {
  display: flex;
  flex-direction: column;
}

.history__info-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: #6f7974;
  margin: 0;
}

.history__info-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #191c1b;
  margin: 0;
}

.history__open-button {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}


.history__heading-arrow{
  transition: transform 0.3s ease-in-out;
  
}

.history__heading-arrow_rotated{
  transform: rotate(180deg);
}